import { Endpoint } from "../utils/endpoind";
import { buildQueryString } from "../utils/functions";
import { deleteData, getData, postData, putData } from "../utils/request";

export const saveSalesForecast = async (payload) => {
  return await postData({ url: `${Endpoint.CREATE_TIME_FORECAST}`, payload });
};
export const editSalesForecastItem = async (payload, id) => {
  return await putData({
    url: `${Endpoint.UPDATE_ITEM_TIME_FORECAST}/${id}`,
    payload,
  });
};

export const getListDataSalesForest = async () => {
  // const query = buildQueryString(payload);
  return await getData({ url: `${Endpoint.GET_LIST_TIME_FORECAST}` });
};
export const deleteSalesForecastItem = async (id) => {
  // const query = buildQueryString(payload);
  return await deleteData({
    url: `${Endpoint.DELETE_ITEM_TIME_FORECAST}/${id}`,
  });
};
