// import { BASE_API_URL_LO_TRINH_NHAN_VIEN } from "@utils/constants";

const BASE_API_URL_SALES_FORESTING = process.env.REACT_APP_API_SALES_FORESTING;
export const API_SALES_FORESTING = {
  CREATE_TIME_FORECAST: `${BASE_API_URL_SALES_FORESTING}/api/time-forecast/store`,
  DETAIL_ITEM: `${BASE_API_URL_SALES_FORESTING}/api/time-forecast/detail`,
  GET_LIST_TIME_FORECAST: `${BASE_API_URL_SALES_FORESTING}/api/time-forecast`,
  UPDATE_ITEM_TIME_FORECAST: `${BASE_API_URL_SALES_FORESTING}/api/time-forecast/update`,
  DELETE_ITEM_TIME_FORECAST: `${BASE_API_URL_SALES_FORESTING}/api/time-forecast/delete`,
};
