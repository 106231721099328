import { ConfigProvider } from 'antd';
import './App.css';
import SalesForecasting from './SalesForecasting/salesforecast'

function App() {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Nunito Sans, sans-serif',
            colorPrimary: '#0288d1',
            colorBorder: '#e3e6ef',
            borderRadius: 8,
            colorTextPlaceholder: '#b7c2c5',
            controlHeightLG: 42,
            fontSizeLG: 14
          },
        }}
      >

        <SalesForecasting />
      </ConfigProvider>
    </>
  );
}

export default App;
