// import { notification } from "antd";
import axios from "axios";
import { encode } from "js-base64";

const defaultHeader = {
  Authorization: `Bearer ${
    sessionStorage.getItem("accessToken") ||
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS1kZXYtb25lLWxvZ2luLnRrZ3BsYXRmb3JtLmNvbS5hdS9hcGkvYXV0aGVudGljYXRlL3NpZ24taW4iLCJpYXQiOjE2Nzg5NTU5MTMsImV4cCI6MTY3ODk1OTUxMywibmJmIjoxNjc4OTU1OTEzLCJqdGkiOiJRcHRwcmtKQWRTMmw1NnE2Iiwic3ViIjoiMjQiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.cwUpL6ze7g2c3eV4LYA3kNhUN8LlEUEEiATmBkW7xbA"
  }`,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

const instance = axios.create({
  baseURL: process.env.TIME_REPORT_API_URL,
  timeout: 5000000,
  headers: defaultHeader,
});

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (
      error.response.data.meta.status === 401 &&
      error.response.data.meta.message === "Token Expired !"
    ) {
      const config = error.response.config || {};
      const iframe = document.getElementById("iframe-sso");
      iframe.onload = function () {
        iframe.contentWindow.postMessage(
          { type: "refresh-token", token: error.response.data.response.token },
          iframe.getAttribute("base-src")
        );
      };
      if (iframe.getAttribute("base-src")) {
        iframe.setAttribute(
          "src",
          `${iframe.getAttribute("base-src")}verify/${encode(
            error.response.data.response.token
          )}?from-iframe=true`
        );
      }
      config.headers.Authorization =
        "Bearer " + error.response.data.response.token;
      return instance(config);
    } else if (error.response.data.meta.status === 401) {
      if (process.env.REACT_APP_ENV === "production") {
        window.location.replace("https://onelogin.tkgplatform.com.au");
      }
    }
  }
);

export const getData = async ({ url, headers = defaultHeader }) => {
  const res = await instance.get(url, {
    headers: {
      ...headers,
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
  });
  return res;
};

export const postData = async ({ url, payload, headers = defaultHeader }) => {
  const res = await instance.post(url, payload, {
    headers: {
      ...headers,
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
  });
  return res;
};

export const putData = async ({ url, payload, headers = defaultHeader }) => {
  const res = await instance.put(url, payload, {
    headers: {
      ...headers,
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
  });
  return res;
};
export const deleteData = async ({ url, headers = defaultHeader }) => {
  const res = await instance.delete(url, {
    headers: {
      ...headers,
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
  });
  return res;
};
