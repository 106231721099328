import React from "react";
import {
  InputTKG,
  SelectTKG,
  TextEditorTKG,
  InputNumberTKG,
} from "tkg-composite-ui";
import { useState } from "react";
import { Button, Col, Form, Row, DatePicker, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  editSalesForecast,
  editSalesForecastItem,
  saveSalesForecast,
} from "./service";
import moment from "moment";
import Swal from "sweetalert2";
import { FormOutlined } from "@ant-design/icons";

dayjs.extend(customParseFormat);

const CardStyle = styled.div`
  padding: 1em;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%),
    0 2px 4px 0 rgb(0 0 0 / 2%);
  border-radius: 10px;
  margin-bottom: 1rem;
`;

const ModalStyle = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    .ant-modal-header {
      background: #0288d1;
      padding: 14px;
      margin-bottom: 0;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-footer {
      padding: 14px;
      margin-top: 0;
    }
    .ant-modal-body {
      background: #f0f0f0;
      padding: 26px 20px;
    }
  }
`;

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const EditSalseForecast = (props) => {
  const query = new URLSearchParams(window.location.search);
  const { getListDataTable, dataEdit } = props;
  const schemaData = JSON.parse(dataEdit.schemaData);
  const [formCreateSalesForecast] = Form.useForm();
  const [dataSave, setDataSave] = React.useState({});
  const [timeType, setTimeType] = React.useState(dataEdit.time_type);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const dataSelect = {
    category: [
      { label: "Tour", value: "tour" },
      { label: "Cruise", value: "cruise" },
      { label: "Rail", value: "rail" },
      { label: "Package", value: "package" },
      { label: "Other", value: "other" },
      { label: "All", value: "all" },
    ],
    curency: [
      { label: "USD", value: "USD" },
      { label: "AUD", value: "AUD" },
      { label: "EUR", value: "EUR" },
      { label: "NZD", value: "NZD" },
      { label: "GBP", value: "GBP" },
      { label: "CAD", value: "CAD" },
    ],
    type: [
      { label: "Monthly", value: "month" },
      { label: "Quarter", value: "quarter" },
      { label: "Yearly", value: "year" },
    ],
  };

  const onSaveSalesForecast = async () => {
    try {
      const CreateSalesForecast =
        await formCreateSalesForecast.validateFields();
      setDataSave(CreateSalesForecast);

      const payload = {
        user_id: query.get("id"),
        category: CreateSalesForecast.category,
        time_type: CreateSalesForecast.timeType,
        currency: CreateSalesForecast.curency,
        schemaData: CreateSalesForecast.schemaData.map((item) => ({
          ...item,
          timeForecast: item.timeForecast,
        })),
      };
      editSalesForecastItem(payload, dataEdit.id).then(async (res) => {
        setOpen(false);
        await getListDataTable();

        Toast.fire({
          icon: "success",
          title: "Signed in successfully",
        });
      });
    } catch (ex) {
      console.log("Validate CreateSalesForecast Failed:", ex);
    }
  };

  const checkTimeType = () => {
    setTimeType(formCreateSalesForecast.getFieldValue("timeType"));
    // if (timeType) {

    // }
  };

  return (
    <>
      <Button
        type="primary"
        className="btnTKGAction"
        icon={<FormOutlined />}
        ghost
        onClick={showDrawer}
      >
        Edit
      </Button>
      <ModalStyle
        title="Edit Sales Forecasting"
        // centered
        width={window.innerWidth > 900 ? "70%" : "100%"}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
        footer={
          <Button
            type="primary"
            className="btnTKGAction"
            onClick={onSaveSalesForecast}
          >
            {" "}
            Save
          </Button>
        }
        onCancel={onClose}
        open={open}
      >
        <Form
          form={formCreateSalesForecast}
          layout="vertical"
          initialValues={{
            category: dataEdit.category,
            timeType: dataEdit.time_type,
            curency: dataEdit.currency,
          }}
        >
          <Row gutter={["16", "16"]}>
            <Col span={8}>
              <Form.Item
                label="Category"
                name="category"
                rules={[
                  {
                    required: true,
                    message: "Please select Category !",
                  },
                ]}
              >
                <SelectTKG
                  form={formCreateSalesForecast}
                  options={dataSelect.category}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Type"
                name="timeType"
                rules={[
                  {
                    required: true,
                    message: "Please select Type !",
                  },
                ]}
              >
                <SelectTKG
                  form={formCreateSalesForecast}
                  options={dataSelect.type}
                  onChange={checkTimeType}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Curency"
                name="curency"
                rules={[
                  {
                    required: true,
                    message: "Please select Curency !",
                  },
                ]}
              >
                <SelectTKG
                  form={formCreateSalesForecast}
                  options={dataSelect.curency}
                />
              </Form.Item>
            </Col>
          </Row>

          {timeType ? (
            <>
              <Form.List
                name="schemaData"
                initialValue={schemaData.map((item) => ({
                  timeForecast: dayjs(item.timeForecast),
                  amount: item.amount,
                  noBookings: item.noBookings,
                  amountAvagePerBooking: item.amountAvagePerBooking,
                  note: item.note,
                }))}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <CardStyle key={key}>
                        <Row gutter={["16", "16"]}>
                          <Col span={8}>
                            <Form.Item
                              {...restField}
                              label={"Time Forecast"}
                              name={[name, "timeForecast"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing Time Forecast",
                                },
                              ]}
                            >
                              <DatePicker
                                style={{ width: "100%" }}
                                size="large"
                                form={formCreateSalesForecast}
                                picker={timeType}
                              />
                              {/* <DatePickerTKG form={formCreateSalesForecast} picker={timeType} /> */}
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...restField}
                              label={"Amount"}
                              name={[name, "amount"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing amount",
                                },
                              ]}
                            >
                              <InputNumberTKG
                                form={formCreateSalesForecast}
                                placeholder="Input Amount"
                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={["16", "16"]}>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              label={"No bookings"}
                              name={[name, "noBookings"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing No Bookings",
                                },
                              ]}
                            >
                              <InputTKG
                                form={formCreateSalesForecast}
                                placeholder="No of Bookings"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              label={"Amount Avage per booking"}
                              name={[name, "amountAvagePerBooking"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing Amount",
                                },
                              ]}
                            >
                              <InputNumberTKG
                                form={formCreateSalesForecast}
                                placeholder="Amount Avage per booking"
                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item {...restField} name={[name, "note"]}>
                              <TextEditorTKG
                                form={formCreateSalesForecast}
                                placeholder="Note"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Button
                          type="default"
                          onClick={() => remove(name)}
                          danger
                        >
                          Remove
                        </Button>

                        {/* <MinusCircleOutlined  /> */}
                      </CardStyle>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add field
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </>
          ) : (
            ""
          )}
        </Form>
      </ModalStyle>
    </>
  );
};

export default EditSalseForecast;
