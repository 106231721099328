import React, { useEffect, useState } from "react";
import CreateForecast from "./createSalesForecast";
import { Space, Table, List, Button } from "antd";
import { deleteSalesForecastItem, getListDataSalesForest } from "./service";
import { DeleteOutlined } from "@ant-design/icons";
import EditSalseForecast from "./editSalesForecast";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import moment from "moment";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
const SalesForecasting = () => {
  const [dataTable, setDataTable] = useState([]);
  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Time Type",
      dataIndex: "time_type",
      key: "time_type",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      render: (_, record) => <span>{record?.user?.name}</span>,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <EditSalseForecast
            getListDataTable={getListDataTable}
            dataEdit={record}
          />
          <Button
            type="primary"
            className="btnTKGDeny"
            icon={<DeleteOutlined />}
            ghost
            onClick={() => handleDeleteSalesItem(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];
  const columnsSchema = [
    {
      title: "Time Forecast",
      dataIndex: "timeForecast",
      key: "timeForecast",
      render: (_, record) => {
        console.log(record);
        return (
          <span>
            {record.time_type === "quarter"
              ? "Q" +
                moment(record.timeForecast).quarter() +
                "-" +
                moment(record.timeForecast).year()
              : record.time_type === "year"
              ? moment(record.timeForecast).year()
              : moment(record.timeForecast).format("MM-YYYY")}
          </span>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "No Bookings",
      dataIndex: "noBookings",
      key: "noBookings",
    },
    {
      title: "Amount Avage Per Booking",
      dataIndex: "amountAvagePerBooking",
      key: "amountAvagePerBooking",
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      render: (_, record) => (
        <div
          dangerouslySetInnerHTML={{
            __html: record?.note,
          }}
        ></div>
      ),
    },
  ];

  const getListDataTable = async () => {
    const res = await getListDataSalesForest();
    console.log(res.data.data);
    setDataTable(res.data.data);
  };

  useEffect(() => {
    getListDataTable();
  }, []);

  const handleDeleteSalesItem = (id) => {
    deleteSalesForecastItem(id).then(async (res) => {
      await getListDataTable();
      Toast.fire({
        icon: "success",
        title: "Signed in successfully",
      });
    });
  };
  return (
    <>
      <div style={{ padding: 10 }}>
        <h5 style={{ fontSize: 24, margin: "15px 0" }}>Sales Forecasting</h5>
        <CreateForecast getListDataTable={getListDataTable} />
        <Table
          columns={columns}
          dataSource={dataTable}
          expandable={{
            expandedRowRender: (record) => {
              const listData = JSON.parse(record.schemaData).map(
                (item, index) => ({
                  ...item,
                  key: index,
                  time_type: record.time_type,
                })
              );
              return (
                <Table
                  size="small"
                  columns={columnsSchema}
                  dataSource={listData}
                  pagination={false}
                  rowKey="key"
                />
              );
            },
          }}
          rowKey="id"
        />
      </div>
    </>
  );
};

export default SalesForecasting;
